import React, { useCallback, useEffect, useRef, useState } from "react";
import cn from "classnames";
import useSlider from "utils/hooks/useSlider";
import { IProductImageItem } from "types/IProductImageItem";
import { ProductProgressiveImage } from "pages/SingleProductPage/Components/ProductGallery/components/ProductProgressiveImage/ProductProgressiveImage";
import { IImagesCache } from "types/IImagesCache";

import DynamicalBullets from "components/UI/DynamicalBullets/DynamicalBullets";

import styles from "./styles.module.scss";
import { ProductModalImage } from "pages/SingleProductPage/Components/ProductGallery/components/ProductModalImage/ProductModalImage";

interface ProductGalleryMobileProps {
  images: IProductImageItem[];
  noImageLink: string;
}

export const ProductGalleryMobile: React.FC<ProductGalleryMobileProps> = ({
  images,
  noImageLink
}) => {
  const [isModalImageOpen, setIsModalImageOpen] = useState(false);

  const imagesCacheRef = useRef<IImagesCache>({});
  const modalImageIndex = useRef<number>(0);
  const modalImageRef = useRef<HTMLDivElement | null>(null);

  const { currentSlide, isLoaded, availableDots, sliderRef, instanceRef } =
    useSlider({
      shouldBeLooped: true,
      shouldAutoSwitched: false
    });

  useEffect(() => {
    return () => {
      setIsModalImageOpen(false);
    };
  }, []);

  const handleOpenModalImage = useCallback((imageIndex: number) => {
    modalImageIndex.current = imageIndex;
    setIsModalImageOpen(true);
  }, []);

  return (
    <div className={styles["mobile-gallery"]}>
      {!images.length ? (
        <img
          src={noImageLink}
          alt=""
          className={styles["mobile-gallery__no-image"]}
        />
      ) : (
        <div ref={sliderRef} className={cn("keen-slider")}>
          {images.map((item, idx) => (
            <div
              key={`${idx}`}
              className="keen-slider__slide"
              onClick={() => handleOpenModalImage(idx)}
            >
              <ProductProgressiveImage
                imagesCache={imagesCacheRef.current}
                thumbnail_q_100={item.thumbnail_q_100}
                thumbnail_q_0={item.thumbnail_q_0}
                thumbnail_q_50={item.thumbnail_q_50}
                original={item.original}
                customStyles={styles["mobile-gallery__image"]}
              />
            </div>
          ))}
        </div>
      )}
      {availableDots?.length > 1 && isLoaded && instanceRef.current && (
        <div className={styles.pagination}>
          <DynamicalBullets
            availableDots={availableDots}
            currentSlide={currentSlide}
            instanceRefSlider={instanceRef}
            dotsStyles={styles["slider-dots"]}
          />
        </div>
      )}
      {isModalImageOpen && images.length && (
        <ProductModalImage
          originalSrc={images[modalImageIndex.current].original.link}
          modalRef={modalImageRef}
          onClose={() => setIsModalImageOpen(false)}
          imagesCache={imagesCacheRef.current}
        />
      )}
    </div>
  );
};
