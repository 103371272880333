import { IImage } from "types/IImage";
import { IProductImageItem } from "types/IProductImageItem";

export interface IProductImageData {
  mainProductImage: {
    image: IImage | null;
    reducedImage: IImage | null;
  };
  offers: IProductImageItem[];
}

// Вариант когда и оригинал добавляем в массив картинок
export const createProductImages = (
  data: IProductImageData
): IProductImageItem[] => {
  const imagesArray: IProductImageItem[] = [];

  const { image, reducedImage } = data.mainProductImage;
  if (image || reducedImage) {
    const mainImageItem: IProductImageItem = {
      thumbnail_q_0: reducedImage,
      thumbnail_q_50: null,
      thumbnail_q_100: null,
      original: image
    };

    if (mainImageItem.original) {
      imagesArray.push(mainImageItem);
    }
  }

  if (Array.isArray(data.offers) && data.offers.length > 0) {
    imagesArray.push(...data.offers);
  }

  return imagesArray;
};

// Вариант когда добавляем офферы - но если нет то только оригинал
// export const createProductImages = (
//   data: IProductImageData
// ): IProductImageItem[] => {
//   const imagesArray: IProductImageItem[] = [];

//   if (Array.isArray(data.offers) && data.offers.length > 0) {
//     imagesArray.push(...data.offers);
//     return imagesArray;
//   }

//   const { image, reducedImage } = data.mainProductImage;
//   if (image || reducedImage) {
//     const mainImageItem: IProductImageItem = {
//       thumbnail_q_0: null,
//       thumbnail_q_50: null,
//       thumbnail_q_100: reducedImage,
//       original: image
//     };

//     if (mainImageItem.original) {
//       imagesArray.push(mainImageItem);
//     }
//   }

//   return imagesArray;
// };
