import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IProductWithOffers } from "types/IProduct";
import { IOldPriceData, IPriceData, ISaleData, IVolumeData } from "../../../../utils/webWorkers/landingProductCardWebWorker";

import styles from "./styles.module.scss";
import { SkeletonLine } from "components/UI/Skeleton/SkeletonLine/SkeletonLine";
import { removeSpaces } from "utils/helpers/removeSpaces";

export type WorkerMessageData =
  | { type: "priceData", data: IPriceData }
  | { type: "saleData", data: ISaleData }
  | { type: "volumeData", data: IVolumeData }
  | { type: "oldPriceData", data: IOldPriceData };

interface BestsellersProductCardProps {
  product: IProductWithOffers;
  cardRef?: React.Ref<HTMLAnchorElement>;
}

const cache = new Map<string, WorkerMessageData[]>();

const LandingProductCard: React.FC<BestsellersProductCardProps> = ({ product, cardRef }) => {

  const [priceData, setPriceData] = useState<IPriceData>({ minPrice: null, maxPrice: null });
  const [saleData, setSaleData] = useState<ISaleData>({ salePercent: null });
  const [volumeData, setVolumeData] = useState<IVolumeData>({ minVolume: null, maxVolume: null, volumeType: null });
  const [oldPriceData, setOldPriceData] = useState<IOldPriceData>({ oldPrice: null });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const productId = product.id.toString();

    if (cache.has(productId)) {
      const cachedData = cache.get(productId);
      cachedData?.forEach(({ type, data }) => {
        if (type === "priceData") setPriceData(data);
        if (type === "saleData") setSaleData(data);
        if (type === "volumeData") setVolumeData(data);
        if (type === "oldPriceData") setOldPriceData(data);
      });
      setLoading(false);
    } else {
      const worker = new Worker(new URL("../../../../utils/webWorkers/landingProductCardWebWorker", import.meta.url));
      worker.postMessage({ product });

      const workerData: WorkerMessageData[] = [];

      worker.onmessage = (e: MessageEvent<WorkerMessageData>) => {
        const { type, data } = e.data;
        workerData.push({ type, data } as WorkerMessageData);

        if (type === "priceData") setPriceData(data);
        if (type === "saleData") setSaleData(data);
        if (type === "volumeData") setVolumeData(data);
        if (type === "oldPriceData") setOldPriceData(data);

        if (workerData.length === 4) {
          cache.set(productId, workerData);
          setLoading(false);
        }
      };

      return () => {
        worker.terminate();
      };
    }
  }, [product]);

  const productUrl = `/product/${product.id}-${product.product_rewrite_name}`;

  const isSinglePrice = priceData.minPrice === priceData.maxPrice;

  const unitCode = volumeData.volumeType === "мл" ? "MLT" :
    volumeData.volumeType === "л" ? "LTR" :
      volumeData.volumeType === "г" ? "GRM" :
        volumeData.volumeType === "кг" ? "KGM" :
          volumeData.volumeType === "шт" ? "H87" :
            volumeData.volumeType === "упаковка" ? "PK" : "";

  return (
    <Link to={productUrl} className={styles.card} ref={cardRef} itemScope itemType="https://schema.org/Product">
      <div className={styles.image}>
        <img src={product.image.link} alt={product.name} itemProp="image"/>
      </div>
      <div className={styles.details}>
        <span className={styles.details__price} itemProp="offers" itemScope itemType="https://schema.org/Offer">
          <meta itemProp="priceCurrency" content="RUB" />
          {loading && (priceData.minPrice === null || priceData.maxPrice === null) ? (
            <SkeletonLine style={{ width: "100px" }}/>
          ) : isSinglePrice ? (
            <span itemProp="price">{priceData.minPrice} ₽</span>
          ) : (
            <>
              <span itemProp="price">{priceData.minPrice}&#8202;₽</span>
              <span className={styles.details__separator}>-</span>
              <span itemProp="price">{priceData.maxPrice}&#8202;₽</span>
            </>
          )}
        </span>
        <div className={styles.details__sale}>
          {loading ? (
            <SkeletonLine style={{ width: "85px", height: "25px", borderRadius: "70px" }}/>
          ) : (
            <>
              {saleData.salePercent && <span className={styles.details__sale__percent}>{!isSinglePrice && "до "} -{saleData.salePercent}%</span>}
              {oldPriceData.oldPrice && <span className={styles["details__old-price"]}>{oldPriceData.oldPrice}₽</span>}
            </>
          )}
        </div>
        <p className={styles.details__name} itemProp="name">{product.name}</p>
        <span className={styles.details__volume}>
          {loading && (volumeData.minVolume === null || volumeData.maxVolume === null || volumeData.volumeType === null) ? (
            <SkeletonLine style={{ width: "25px", height: "10px" }} />
          ) : volumeData.minVolume === volumeData.maxVolume ? (
            <>
              <span itemProp="additionalProperty" itemScope itemType="https://schema.org/PropertyValue">
                <meta itemProp="name" content="size" />
                <meta itemProp="value" content={removeSpaces(volumeData.minVolume)} />
                <meta itemProp="unitCode" content={unitCode} />
                {`${volumeData.minVolume} ${volumeData.volumeType}`}
              </span>
            </>
          ) : (
            <>
              <span itemProp="additionalProperty" itemScope itemType="https://schema.org/PropertyValue">
                <meta itemProp="name" content="size" />
                <meta itemProp="minValue" content={removeSpaces(volumeData.minVolume)} />
                <meta itemProp="maxValue" content={removeSpaces(volumeData.maxVolume)} />
                <meta itemProp="unitCode" content={unitCode} />
                {`${volumeData.minVolume} ${volumeData.volumeType} - ${volumeData.maxVolume} ${volumeData.volumeType}`}
              </span>
            </>
          )}
        </span>
        <meta itemProp="description" content={product.description} />
      </div>
    </Link>
  );
};

export default LandingProductCard;