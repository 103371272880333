import { useMemo } from "react";
import ProductDescription from "./components/ProductDescription/ProductDescription";
import ProductOffers from "./components/ProductOffers/ProductOffers";
import { SkeletonProductDescription } from "../../../../components/UI/Skeleton/SkeletonProductDescription/SkeletonProductDescription";
import { SkeletonLine } from "../../../../components/UI/Skeleton/SkeletonLine/SkeletonLine";

import { IProduct } from "types/IProduct";
import { IProductImageItem } from "types/IProductImageItem";
import { IOffer, IProperty } from "types/IOffer";

import { ProductGalleryDesktop } from "pages/SingleProductPage/Components/ProductGallery/ProductGalleryDesktop/ProductGalleryDesktop";
// В случае необходимости можно использовать моковые данные о фотографиях товара
// import { PRODUCT_MOCK_IMAGES } from "mock/productCardMock/productCardMockImages";
import styles from "./styles.module.scss";

interface IProductMainContentProps {
  isLoading: boolean;
  article?: string;
  product?: IProduct;
  offers?: IOffer[];
  properties?: IProperty[];
  scrollToReviews: () => void;
  name: string;
  type: string;
  productImages: IProductImageItem[];
}

const ProductMainContent: React.FC<IProductMainContentProps> = (props) => {
  const noImageLink = useMemo(() => {
    return props.product?.category.rewrite_name === "kosmetika"
      ? "https://cdn.parfumart.ru/internal-images/no-photo-kosmetic.svg"
      : "https://cdn.parfumart.ru/internal-images/no-photo-parfume.svg";
  }, [props.product?.category.rewrite_name]);

  const isCosmetic = props.product?.category.rewrite_name === "kosmetika";

  return (
    <div className={styles.root}>
      <h1 className={styles.seo}>
        {props.name} {isCosmetic ? "" : props.type}
      </h1>
      <div className={styles["preview-wrapper"]}>
        <div className={styles.preview}>
          <ProductGalleryDesktop
            // images={PRODUCT_MOCK_IMAGES}
            images={props.productImages}
            noImageLink={noImageLink}
          />
          {props.isLoading || !props.product ? (
            <SkeletonProductDescription />
          ) : (
            <ProductDescription
              brandDescription={props.product?.brands[0]?.brand?.description}
              brandTitle={props.product?.brands[0].brand.name}
              brandRewriteName={props.product?.brands[0].brand.rewrite_name}
              productTitle={props.product?.name}
              description={props.product?.description}
              productRewrateName={props.product?.product_rewrite_name}
              category={props.product?.category}
              properties={props.properties}
              offers={props.offers}
              scrollToReviews={props.scrollToReviews}
            />
          )}
        </div>
        <div className={styles["offers-wrapper"]}>
          <div className={styles["offers-wrapper-article"]}>
            {!props.isLoading && props.article ? (
              `Артикул: ${props.article}`
            ) : (
              <SkeletonLine />
            )}
          </div>
          <ProductOffers
            offers={props.offers}
            product={props.product}
            isLoading={props.isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductMainContent;
