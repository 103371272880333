import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import cn from "classnames";
import { IProductImageItem } from "types/IProductImageItem";

import { ProductProgressiveImage } from "pages/SingleProductPage/Components/ProductGallery/components/ProductProgressiveImage/ProductProgressiveImage";
import styles from "./styles.module.scss";
import { IImagesCache } from "types/IImagesCache";
import { ProductModalImage } from "pages/SingleProductPage/Components/ProductGallery/components/ProductModalImage/ProductModalImage";

interface ProductGalleryProps {
  images: IProductImageItem[];
  noImageLink: string;
}

export const ProductGalleryDesktop: React.FC<ProductGalleryProps> = memo(
  (props) => {
    const [isModalImageOpen, setIsModalImageOpen] = useState(false);
    const [activeImageIndex, setActiveImageIndex] = useState<number>(0);
    const [canScrollStart, setCanScrollStart] = useState<boolean>(false);
    const [canScrollEnd, setCanScrollEnd] = useState<boolean>(false);
    const subImagesWrapperRef = useRef<HTMLDivElement | null>(null);
    const imagesCacheRef = useRef<IImagesCache>({});
    const modalImageIndex = useRef<number>(0);
    const modalImageRef = useRef<HTMLDivElement | null>(null);

    const handleImageAction = useCallback((imageIndex: number) => {
      setActiveImageIndex(imageIndex);
    }, []);

    const scrollSubImages = (direction: "start" | "end") => {
      if (subImagesWrapperRef.current) {
        const scrollAmount = subImagesWrapperRef.current.clientHeight;
        subImagesWrapperRef.current.scrollBy({
          top: direction === "end" ? scrollAmount : -scrollAmount,
          behavior: "smooth"
        });
      }
    };

    const updateScrollButtonsVisibility = () => {
      if (subImagesWrapperRef.current) {
        const { scrollTop, scrollHeight, clientHeight } =
          subImagesWrapperRef.current;
        setCanScrollStart(scrollTop > 0);
        setCanScrollEnd(scrollTop + clientHeight < scrollHeight);
      }
    };

    const handleOpenModalImage = useCallback(() => {
      modalImageIndex.current = activeImageIndex;
      setIsModalImageOpen(true);
    }, [activeImageIndex]);

    useEffect(() => {
      const wrapper = subImagesWrapperRef.current;
      if (wrapper) {
        wrapper.addEventListener("scroll", updateScrollButtonsVisibility);
        updateScrollButtonsVisibility();
      }
      return () => {
        if (wrapper) {
          wrapper.removeEventListener("scroll", updateScrollButtonsVisibility);
        }
      };
    }, [props.images]);

    return (
      <div
        className={cn(styles["product-gallery"], {
          [styles["product-gallery--empty"]]: !props.images.length
        })}
      >
        {!props.images.length ? (
          <img
            src={props.noImageLink}
            alt=""
            className={styles["product-gallery__no-image"]}
          />
        ) : (
          <>
            <div className={styles["product-gallery__outer-images-wrapper"]}>
              {canScrollStart && (
                <button
                  onClick={() => scrollSubImages("start")}
                  className={cn(
                    styles["scroll-button"],
                    styles["scroll-button--start"]
                  )}
                >
                  <span />
                  <span />
                </button>
              )}
              <div
                className={styles["product-gallery__sub-images-wrapper"]}
                ref={subImagesWrapperRef}
              >
                {props.images.map((imageItem, index) => (
                  <button
                    key={index}
                    className={cn(styles["product-gallery__sub-image-item"], {
                      [styles["product-gallery__sub-image-item--active"]]:
                        index === activeImageIndex
                    })}
                    onClick={() => handleImageAction(index)}
                  >
                    <ProductProgressiveImage
                      thumbnail_q_100={imageItem.thumbnail_q_100}
                      thumbnail_q_0={imageItem.thumbnail_q_0}
                      thumbnail_q_50={imageItem.thumbnail_q_50}
                      original={imageItem.original}
                      imagesCache={imagesCacheRef.current}
                      customStyles={styles["product-gallery__sub-image"]}
                    />
                  </button>
                ))}
              </div>
              {canScrollEnd && (
                <button
                  onClick={() => scrollSubImages("end")}
                  className={cn(
                    styles["scroll-button"],
                    styles["scroll-button--end"]
                  )}
                >
                  <span />
                  <span />
                </button>
              )}
            </div>
            <div
              className={styles["product-gallery__main-image-wrapper"]}
              onClick={handleOpenModalImage}
            >
              <ProductProgressiveImage
                thumbnail_q_100={props.images[activeImageIndex].thumbnail_q_100}
                thumbnail_q_0={props.images[activeImageIndex].thumbnail_q_0}
                thumbnail_q_50={props.images[activeImageIndex].thumbnail_q_50}
                original={props.images[activeImageIndex].original}
                imagesCache={imagesCacheRef.current}
                customStyles={styles["product-gallery__main-image"]}
              />
            </div>
          </>
        )}
        {isModalImageOpen && props.images.length && (
          <ProductModalImage
            originalSrc={props.images[modalImageIndex.current].original.link}
            modalRef={modalImageRef}
            onClose={() => setIsModalImageOpen(false)}
            imagesCache={imagesCacheRef.current}
          />
        )}
      </div>
    );
  }
);

ProductGalleryDesktop.displayName = "ProductGalleryDesktop";
